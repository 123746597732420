import React from 'react'
import Helmet from 'react-helmet';
import _ from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import { useMetaOverride } from '../lib/hoc/useMeta';

import './styles';

import favicon from '../static/favicon.ico';

const metaQuery = graphql`
  query SEOMetaQuery {
    markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      frontmatter {
        seo {
          url
          title
          type
          description
          image {
            url
            width
            height
          }
        }
      }
    }
  }
`;

const Meta = () => {
  const data = useStaticQuery(metaQuery);
  const post = useMetaOverride('meta', data.markdownRemark);
  const seo = _.get(post, 'frontmatter.seo');

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" type="image/png" href={favicon} />
        {seo.image && <meta name="image" content={seo.image.url} />}
        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.type && <meta property="og:type" content={seo.type} />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && <meta property="og:description" content={seo.description} />}
        {seo.image && <meta property="og:image" content={seo.image.url} />}
        {seo.image && <meta property="og:image:width" content={seo.image.width} />}
        {seo.image && <meta property="og:image:height" content={seo.image.height} />}
        <meta name="twitter:card" content="summary_large_image" />
        {seo.twitterUsername && <meta name="twitter:creator" content={seo.twitterUsername} />}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && <meta name="twitter:description" content={seo.description} />}
        {seo.image && <meta name="twitter:image" content={seo.image.url} />}
      </Helmet>
    </>

  );
};

export default Meta;

