import React from 'react';
import styled from 'styled-components';

import MobileNavigation from './MobileNavigation';
import TabletDesktopNavigation from './TabletDesktopNavigation';

import headerBackground from '../static/images/triangle-grey.svg';

const BlogBackground = styled.div`
  background-color: #F4F4F4;
  background-image: url(${ headerBackground });
  background-size: 100%;
  background-position: center;
`;

export const BFBlogHeader = () => (
  <BlogBackground className="bf-blog-header">
    <TabletDesktopNavigation />
    <MobileNavigation />
    <div />
  </BlogBackground>
);

export default BFBlogHeader;
