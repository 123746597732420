import React, { createContext, useContext } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "styled-components";
import HomeLayout from "../components/HomeLayout";
import ReactMarkdown from "react-markdown";

const OrgHomeStyles = styled.div`
  header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-holster {
      width: 200px;
      height: 200px;
      margin: -100px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      &.logo {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .cover {
      background: url(${({ cover }) => cover});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 30%;
      width: 100vw;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      /* TODO: figure out background opacity. Asha changed this from .33 to .63 to increase the opacity for our demo */
      background-color: rgba(0, 0, 0, 0.63);
      background-blend-mode: darken;

      h1 {
        font-size: 48px;
        color: white;
        text-align: center;

        &::after {
          content: ".";
        }
      }
    }
  }

  main {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-size: 18px;

    h2 {
      text-align: center;
    }

    li {
      margin-bottom: 0.5em;
    }

    .paragraph {
      line-break: normal;
    }
  }

  button {
    display: block;
    border-radius: 1000px;
    padding: 10px 30px;
    border: none;
    background: ${({ main }) =>
      main}; // it may be worthwhile to try to determine whether
    /* color: white; // or not this should be white or black, depending on the main color */
    color: ${({ secondary }) =>
      secondary}; // or not this should be white or black, depending on the main color
    font-family: "Poppins", sans-serif;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
  }

  @media (min-width: 768px) {
    header {
      margin-top: -30px;

      .cover {
        height: 40vh;

        h1 {
          font-size: 72px;
        }
      }
    }
  }
`;

const PageContext = createContext({});

export const usePageContext = () => useContext(PageContext);

const OrganizationHome = ({ data: { hasura }, ...rest }) => {
  const organization = hasura?.organization?.[0];
  const slug = rest.pageContext?.slug ?? "";
  const { pageContext } = rest;

  return (
    <PageContext.Provider value={{ pageData: rest, slug, pageContext }}>
      <HomeLayout>
        <Helmet title={organization?.organization_name ?? ""} />

        <OrgHomeStyles
          main={organization.primary_color}
          secondary={organization.secondary_color}
          tertiary={organization.tertiary_color}
          cover={organization.cover_image}
        >
          <header>
            <div className="cover">
              <h1>{organization.organization_name}</h1>
            </div>
            <div className="logo-holster">
              <img
                className="logo"
                src={organization.logo_image}
                alt={`${organization.organization_name} logo`}
              />
            </div>
          </header>
          <main>
            <ReactMarkdown className="paragraph">
              {organization?.custom_landing_text}
            </ReactMarkdown>

            <h2>How it works</h2>
            <ReactMarkdown className="paragraph">
              {organization?.how_it_works_text}
            </ReactMarkdown>

            <h2>Privacy and security</h2>
            <ReactMarkdown className="paragraph">
              {organization?.privacy_security_text}
            </ReactMarkdown>

            <a href="/eligibility-form">
              <button>Begin</button>
            </a>
          </main>
        </OrgHomeStyles>
      </HomeLayout>
    </PageContext.Provider>
  );
};

export const OrganizationCustomization = graphql`
  query MyQuery($slug: String!) {
    hasura {
      organization(where: { slug: { _eq: $slug } }) {
        slug
        organization_name
        state_code
        cover_image
        logo_image
        primary_color
        secondary_color
        tertiary_color
        custom_landing_text
        privacy_security_text
        how_it_works_text
        post_submission_text
      }
    }
  }
`;

export default OrganizationHome;
