import styled from 'styled-components';
import { Input, Form } from 'semantic-ui-react';

const SubscribeInput = styled(Input)`
  input, .button {
    padding: 1.1em 1.5em;
    font-size: .78em;
    border: none;
    
    @media (min-width: 601px) {
      font-size: .91em;
    }
  }
`;

const SubscribeForm = styled(Form)`

#reactHubspotForm0, .hbsptform {
  /* background-color: pink !important; */
  /* display: none !important; */
  /* font-family: 'Poppins' !important; */
  /* margin-bottom: 0px !important; */
  .sproket {
    margin: 0px !important;

  }
}
  .hbsptform .hs-form-field {
    margin-bottom: 0;
  }

  /* .hs-form-iframe form {
    width: 99% !important;

  } */
  .bfForm input[type="email"]:focus {
    border: 1px solid #FA6E59 !important;
  }

  .hs-form-field label {
    display: none;
  font-family: 'Poppins' !important;

  }

  .bfForm input[type="email"], .bfForm input[type="email"]:focus {
    padding: 1.1em 1.5em;
    font-size: 12px;
    border: none;
    border-radius: 23px;
    float: left;
    margin-bottom: 0px;
    margin-right: 10px;
    max-width: 75%;
    
    @media (min-width: 601px) {
      font-size: 14px;
    }
  }

  .bfForm .hs-button.primary {
    padding: 23px;
    border-radius: 23px;
    background-color: #15919B;
    border: none;
    color: white;
    padding: 1.2em 1.5em;
    font-size: 12px;
    /* font-family: 'Open Sans' !important; */
    margin-top: 10px;
    font-weight: 500;
    
    @media (min-width: 382px) {
      margin-top: 0px;
    }

    @media (min-width: 601px) {
      font-size: 14px;
    }
  }

  .submitted-message {
    font-size: 16px;
    font-weight: 600;
    color: #FA6E59;
  }
`;

export default SubscribeInput;
export { SubscribeForm };