import React from "react";
import {
  Responsive,
  Container,
  Menu,
  Button,
  Image,
  Header,
} from "semantic-ui-react";
import _ from "lodash";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import SignUpForm from "./SignUpForm";
import { useMetaOverride } from "../lib/hoc/useMeta";
import { usePageContext } from "../templates/organization-home";

const FullMenu = styled(Menu)`
  font-size: 16px !important;

  .item {
    color: #cd5b49 !important;
  }
  .active,
  .item:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }
  .right {
    .item.btn {
      &:hover {
        text-decoration: none !important;
      }
    }
  }
`;

const SignUpHeader = styled(Header)`
  color: #cd5b49 !important;
  text-align: center;
  margin-bottom: 0.5em !important;
`;

const SignUpBtn = styled(Button)`
  font-size: 16px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  border: none !important;
  padding-left: 55px !important;
  padding-right: 55px !important;
  background-color: #cd5b49 !important;
  color: white !important;

  :hover {
    background-color: white !important;
    color: #cd5b49 !important;
  }
`;

const SubmitResBtn = styled(Button)`
  font-weight: 600 !important;

  :hover {
    border: 1px solid #cd5b49 !important;
    text-decoration: none !important;
    background-color: #cd5b49 !important;
    color: white !important;
  }
`;

const LogInBtn = styled(Button)`
  font-size: 16px !important;
  color: #fa6e59 !important;
  font-weight: 400 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;

  :hover {
    background-color: #fa6e59 !important;
    color: white !important;
  }
`;

const metaQuery = graphql`
  query MenuMetaQuery {
    markdownRemark(fields: { slug: { eq: "/meta/" } }) {
      frontmatter {
        logo {
          title
          href
          disable
          src {
            publicURL
          }
        }
        menus {
          links {
            title
            href
            disable
          }
          buttons {
            title
            disable
            href
          }
        }
      }
    }
  }
`;

const TabletDesktopNavigation = () => {
  const [activeItem, $activeItem] = React.useState("home");
  const handleItemClick = (e, { name }) => $activeItem(name);
  // getActiveLinkProps = ({ isPartiallyCurrent, ...rest }) => {
  //   return isPartiallyCurrent
  //   ? { className: 'item active' }
  //   : { className: 'item' }
  // };

  const { slug } = usePageContext();

  const data = useStaticQuery(metaQuery);
  const post = useMetaOverride("meta", data.markdownRemark);

  return (
    <div>
      <Responsive minWidth={768}>
        <Container
          style={{ backgroundColor: "transparent", marginBottom: "30px" }}
        >
          <FullMenu borderless secondary>
            <Menu.Menu position="left" className="left">
              <Menu.Item
                as={Link}
                name="home"
                onClick={handleItemClick}
                href={_.get(post, "frontmatter.logo.href")}
                to={_.get(post, "frontmatter.logo.href")}
                fitted="horizontally"
              >
                <Image
                  rounded
                  src={_.get(post, "frontmatter.logo.src.0.publicURL")}
                  height="55px"
                  floated="left"
                  alt="Logo"
                />
              </Menu.Item>
              <Menu.Item
                as={Link}
                name="home"
                onClick={handleItemClick}
                href={_.get(post, "frontmatter.menus.links.0.href")}
                to={_.get(post, "frontmatter.menus.links.0.href")}
                fitted="horizontally"
              >
                {_.get(post, "frontmatter.menus.links.0.title")}
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position="right" className="right">
              {slug && (
                <Menu.Item
                  as="a"
                  name="login"
                  onClick={handleItemClick}
                  active={activeItem === "login"}
                  target="_blank"
                  fitted
                  className="btn"
                  href={`https://main.ddezjhzcx2yro.amplifyapp.com/${slug}/signin`}
                >
                  <LogInBtn inverted color="red">
                    Log In
                  </LogInBtn>
                </Menu.Item>
              )}
              {!_.get(post, "frontmatter.menus.buttons.0.disable") && (
                <Menu.Item
                  as="a"
                  name="submitres"
                  {..._.omitBy(
                    { href: _.get(post, "frontmatter.menus.buttons.0.href") },
                    _.isNil
                  )}
                  target="_blank"
                  fitted
                  className="btn"
                >
                  <SubmitResBtn inverted color="pink">
                    {_.get(post, "frontmatter.menus.buttons.0.title")}
                  </SubmitResBtn>
                </Menu.Item>
              )}
              {!_.get(post, "frontmatter.menus.buttons.1.disable") && (
                <Menu.Item
                  as="a"
                  name="signup"
                  {..._.omitBy(
                    { href: _.get(post, "frontmatter.menus.buttons.1.href") },
                    _.isNil
                  )}
                  onClick={handleItemClick}
                  active={activeItem === "signup"}
                  fitted
                  className="btn"
                >
                  <SignUpForm
                    trigger={
                      <SignUpBtn color="pink">
                        {_.get(post, "frontmatter.menus.buttons.1.title")}
                      </SignUpBtn>
                    }
                  />
                </Menu.Item>
              )}
            </Menu.Menu>
          </FullMenu>
        </Container>
      </Responsive>
    </div>
  );
};

export default TabletDesktopNavigation;

export { LogInBtn, SignUpHeader };
