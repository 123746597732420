import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import ScrollUpButton from 'react-scroll-up-button';

import './styles';
import BFBlogHeader from './BFBlogHeader';
import Footer from './Footer';
import Page from './Page';
import Meta from './Meta';

const HomeLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HomeLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div>
          <Meta />
          <BFBlogHeader />
          <Page>{children}</Page>
          <Footer className='bf-blog-footer' />
          <ScrollUpButton />
        </div>
      );
    }}
  />
);

export default HomeLayout;
