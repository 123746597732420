import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import styled from 'styled-components';
import { useMetaOverride } from '../lib/hoc/useMeta';

const FormWrapper = styled.div`
  font-family: Poppins;
  h2 {
    max-width: 380px;
    font-weight: 500 !important;
  }
  #mc_embed_signup {
    background: transparent !important;
  }
  #mce-EMAIL {
    border-radius: 19px !important;
    border: none !important;
    padding: 8px 10px !important;
  }
  .indicates-required {
    display: none;
  }
  .mc-field-group {
    width: 240px !important;
    float: left;
    label {
      display: none !important;
    }
    margin-right: 20px;
  }
  .clear {
    clear: none !important;
    .button {
      border-radius: 19px !important;
      background: #005F64 !important;
    }
  }
`;

export const MailchimpSubcribeForm = () => {
  const data = useStaticQuery(graphql`
    query FooterFormText {
      markdownRemark(fields: { slug: { eq: "/form/footer/" }}) {
        frontmatter {
          description
        }
      }
    }
  `);

  const post = useMetaOverride('footer', data.markdownRemark);
  const signupHtml = _.get(post, 'frontmatter.description');
  return (
    <FormWrapper 
      dangerouslySetInnerHTML={{
        __html: signupHtml,
      }}
    />
  );
};

export default MailchimpSubcribeForm;
