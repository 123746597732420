import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import styled from 'styled-components';

import { useMetaOverride } from '../lib/hoc/useMeta';

const FormWrapper = styled.div`
  font-family: Poppins;
  h2 {
    display: none !important;
  }
  #mc_embed_signup {
    background: transparent !important;
  }
  .indicates-required {
    display: none;
  }
  .mc-field-group {
    font-family: Poppins;
    label {
      font-size: 16px;
      font-weight: 500;
    }
    input {
      border-radius: 19px !important;
      padding: 8px 10px !important;
  
    }
    &.size1of2 {
      display: none !important;
    }
  }
  .clear {
    .button {
      border-radius: 23px !important;
      background: #CD5B49 !important;
      margin: 0 auto !important;
      display: block !important;
      min-width: 200px;
      font-family: Poppins;
      padding: 10px 22px !important;
      height: 46px !important;
    }
  }
`;

const SignupFormQuery = graphql`
query MailChimpSignupFormText {
  markdownRemark(fields: { slug: { eq: "/form/signup/" }}) {
    frontmatter {
      description
      headerText
    }
  }
}
`

export const MailchimpForm = () => {
  const data = useStaticQuery(SignupFormQuery);
  const post = useMetaOverride('signup', data.markdownRemark);
  const signupHtml = _.get(post, 'frontmatter.description');
  return (
    <FormWrapper 
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: signupHtml,
      }}
    />
  );
};

export default MailchimpForm;
