import React from 'react';
import { IntlProvider } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';
import 'typeface-open-sans';

// TODO: update theme
const theme = {
  sizeHighlight: '55px',
  sizeHeader: '30px',
  sizeMobileText: '16px',
  sizeText: '21px',
  sizeSubtext: '14px',
  sizeSmallText: '11px',

  // color palette
  brandPink: '#FF6250',
  primaryBlack: '#424242',
  primaryDarkGrey: '#707070',
  primaryGrey: '#AAAAAA',
  primaryLightGrey: '#E6E6E6',
  primaryTeal: '#15919B',
  primaryPink: '#FA6E59',
  primaryOrange: '#F8A055',
  primaryYellow: '#FFDB5C',
  primaryWhite: '#FFFFFF',
  altTeal: '#00949d',
  altPink: '#FF624F',
  altOrange: '#FF9C41',
  altDarkYellow: '#FFDB39',
  altLightYellow: '#FDE05B',
  accentPurple: '#F910C7',
  accentDarkBlue: '#2103F7',
  accentLightBlue: '#11BEF4',
  accentGreen: '#94FF2E',
  accentTeal: '#03FFE0',
  altAccentPurple: '#A95666', // E05CB4
  altAccentDarkBlue: '#293850',
  altAccentLightBlue: '#74C4E1',
  altAccentGreen: '#C1FF07',
  altAccentTeal: '#BAEDB1', // 7DB587 3BB4AE
  divider: '1px solid rgba(34,36,38,.15)',

  bgOrange: '#F8A055E6',
  bgTeal: '#005F64',
  bgYellow: '#FFDB5CE6',
  bgPink: '#FA6E59E6',
  bgGreen: '#94FF2E',
  bgWhite: '#FFFFFF',
  bg1: 'rgba(250, 236, 209, 0.45)',

  txtOrange: '#C27D3D',
  txtTeal: '#005F64',
  txtYellow: '#8A7441',
  txtPink: '#CD5B49',
  txtGreen: '#94FF2E',
  txtWhite: '#FFFFFF',
  txtColor1: '#005F64',
  txtColor2: '#C27D3D',
  txtColor3: '#CD5B49',
  txtColor4: '#CD5B49',

  txtLight: '#7F7F7F',
  // end color palette

  mobile: '600px',
  notMobile: '601px',
  tablet: '992px',
  notDesktop: '992px',
  maxWidth: '1000px',
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',

  // spacing
  p1: '20px',
  m1: '20px',

  ps1: '24px',
  ms1: '24px',
  // fontsize

  queryMobile: 'max-width: 600px'
};

Object.assign(theme, {
  queries: {
    mobile: `(max-width: ${theme.mobile})`,
    tablet: `(max-width: ${theme.tablet})`,
    desktop: `(min-width: ${theme.tablet})`,
  },
})

const StyledPage = styled.div`
  background: white;
  margin: 0 auto;
  color: ${props => props.theme.primaryBlack};

  font-size: 10px;
  font-weight: 400;
  
  .grid {
    margin: 0px !important;
  }

  &.pageContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .txt-color-1 {
    color: ${props => props.theme.txtColor1};
  }
  .txt-color-2 {
    color: ${props => props.theme.txtColor2};
  }
  .txt-color-3 {
    color: ${props => props.theme.txtColor3};
  }
  .txt-color-4 {
    color: ${props => props.theme.txtColor4};
  }

  .text-p {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 1.4em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 1.6em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 1.6em;
    }
    line-height: 1.5;
    margin-bottom: 0.79rem;
  }
  .text-small {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 1.28em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 1.28em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 1.28em;
    }
    line-height: 1.3;
    margin-bottom: 0.36rem;
  }
  .text-hx {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 3.5em;
      line-height: 1.4;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 4.5em;
      line-height: 1.1;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 5.5em;
      line-height: 1.18;
    }
    margin-bottom: .714rem;
    font-weight: 700;
  }
  .text-h1 {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 3.0em;
      line-height: 1.5;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 4.0em;
      line-height: 1.2;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 5.0em;
      line-height: 1.24;
    }
    margin-bottom: .714rem;
  }
  .text-h2 {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 2.4em;
      line-height: 1.5;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 3.0em;
      line-height: 1.5;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 4.0em;
      line-height: 1;
    }
    margin-bottom: .714rem;
  }
  .text-h3 {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 2.4em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 2.4em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 3.0em;
    }
    line-height: 1.3;
    margin-bottom: .714rem;
  }
  .text-h4 {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 2.0em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 2.4em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 2.4em;
    }
    line-height: 1.3;
    margin-bottom: 0.71rem;
  }
  .text-h5 {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 2.0em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 2.0em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 2.0em;
    }
    line-height: 1.3;
    margin-bottom: 0.36rem;
  }
  .text-h6 {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 2.0em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 2.0em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 2.0em;
    }
    line-height: 1.3;
    margin-bottom: 0.36rem;
    font-weight: 600;
  }
  .text-btn {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 1.4em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 1.6em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 1.6em;
    }
    line-height: 1.5;
    padding: 1.2em;

  }
  .text-sub1 {
    @media ${({ theme }) => theme.queries.mobile} {
      font-size: 2.0em;
    }
    @media ${({ theme }) => theme.queries.tablet} {
      font-size: 2.0em;
    }
    @media ${({ theme }) => theme.queries.desktop} {
      font-size: 2.0em;
    }
    line-height: 1.3;
    margin-bottom: 0.36rem;
  }
  .text-sub2 {

  }


  .trackingData {
    display: none !important;
  }
`;

const Page = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en">
        <StyledPage>
          {children}
        </StyledPage>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default Page;
