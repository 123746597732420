import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Button, Modal, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { useMetaOverride } from '../lib/hoc/useMeta';
import MailchimpForm from './MailchimpForm';

const SignUpHeader = styled(Header)`
  color: #CD5B49 !important;
  text-align: center;
  margin-bottom: .5em !important;
  font-size: 50px !important;
  margin-top: 31px !important;
`;


const SignupFormQuery = graphql`
query SignupFormText {
  markdownRemark(fields: { slug: { eq: "/form/signup/" }}) {
    frontmatter {
      description
      headerText
    }
  }
}
`;

export const SignUpForm = ({
  trigger = (<Button inverted color="red">Sign Up</Button>)
}) => {
  const data = useStaticQuery(SignupFormQuery);
  const post = useMetaOverride('signup', data.markdownRemark);
  
  return (<Modal trigger={trigger} >
    <Modal.Content>
      <SignUpHeader>{post.frontmatter.headerText}</SignUpHeader>
      <MailchimpForm />
    </Modal.Content>
  </Modal>);
}

export default SignUpForm;
